
.fa-check.active[data-v-6d05a5b8], .fa-user-circle-o.active[data-v-6d05a5b8] {
  color:green;
}
.fa-check.disabled[data-v-6d05a5b8], .fa-user-circle-o.disabled[data-v-6d05a5b8] {
  color:red;
}




.clients-list thead[data-v-44595c2d]{
  display:none;
}

